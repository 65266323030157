<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Member Resources</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="body-container">
        <h2 class="subtitle is-size-3">We're Sorry!</h2>
        <p class="is-size-4">This page is currently under construction. Check back soon!</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.body-container {
  margin-top: 40px;
}
</style>